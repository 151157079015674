import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LegalLayout from "../../components/layouts/legal_layout";
export const _frontmatter = {
  "title": "DemandByte Privacy Policy",
  "description": "The privacy practices of DemandByte with regard to data that we collect through websites operated by us."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LegalLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`DemandByte Privacy Policy`}</h1>
    <p><em parentName="p">{`This privacy statement is effective as of August 1, 2020, and will regularly be updated to reflect any changes in the way we handle your personal data or any changes in applicable laws.`}</em></p>
    <p><strong parentName="p">{`DemandByte takes your privacy seriously.`}</strong></p>
    <p><strong parentName="p">{`This Privacy Policy explains the type of personal information we collect, what we do with it, what we don’t do with it, and how we keep it safe. Please read the following information carefully to understand our views and practices regarding your personal data and how we will treat it.`}</strong></p>
    <p>{`This Privacy Policy (“Policy”) describes the privacy practices of DemandByte International, Inc. (“Company,” “we,” and “our”) with regard to data that we collect through websites operated by us (“Sites”), through our social media pages and apps (such as `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/company/demandbyte"
      }}>{`https://www.linkedin.com/company/demandbyte`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/demandbyte"
      }}>{`https://twitter.com/demandbyte`}</a>{` and others (collectively, “Social Media Pages”), through email/text messages we send to you that link to this Policy, and through other services (“Services”) provided by us both online and offline. “You” or “user” refers to any individual who visits or otherwise uses the Sites, Social Media Pages, or Services. “Customer” refers to any person or entity who contracts with us to purchase a product or service.`}</p>
    <p><strong parentName="p">{`Who we are:`}</strong>{` For the purpose of applicable data protection legislation, the data controller of your personal data is DemandByte, 11 Driftwood Dr, Port Washington, New York, NY 11050.`}</p>
    <p><strong parentName="p">{`Changes to this Policy:`}</strong>{` We will post any modifications or changes to the Policy on this page. We reserve the right to modify the Policy at any time, so we encourage you to review it frequently. The effective date above indicates when this Policy was last changed.`}</p>
    <p><strong parentName="p">{`What is not covered by this Policy:`}</strong>{` This Privacy Policy does not apply to the practices of third parties that DemandByte does not own or control, or to individuals that DemandByte does not employ or manage. Our Sites and Services may contain links to third party websites and incorporated their technology and services; these third parties have their own privacy policies and we do not accept any responsibility or liability for their websites, features, or policies.`}</p>
    <p><strong parentName="p">{`1`}{`.`}{` What Types of Information Do We Collect?`}</strong></p>
    <p><strong parentName="p">{`Personal Data`}</strong></p>
    <p>{`We may collect personal data about you such as your name, postal address, email address, and telephone number. As used in this Policy, “personal data” is as defined in the UK Data Protection Act 1998/the European Data Protection Directive 95/46/EC/General Data Protection Regulation 2018 and any successor legislation.`}</p>
    <p>{`Our Sites collect personal data about your computer or mobile device, which may include your IP address, operating system and browser type, details of your visits to our Sites, the pages you view, and resources you access or download (see “How Do We Collect Your Data” below).`}</p>
    <p>{`If you contact us, complete surveys, or post comments or other content on or through our Sites and Services, we may collect that information.`}</p>
    <p><strong parentName="p">{`Anonymous Data`}</strong></p>
    <p>{`We also collect anonymous user data, which means data and information that does not permit you to be identified or identifiable, either alone or when combined with any other information available to a third party. We may create anonymous data from the personal data we receive about you, by excluding information such as your name that makes the data personally identifiable to you. Anonymous data we create might include analytics information and information collected by us using cookies (see the Cookies section below). We analyze this anonymous data to make improvements to our Sites and Services.`}</p>
    <p><strong parentName="p">{`2`}{`.`}{` Why Do We Collect This Information?`}</strong></p>
    <p>{`The primary reason that we collect information from you is to provide you with the Services that you request and to enhance your experience at our Sites. We also use the information we collect to make sure that our Sites and Services provide you with the resources you need and to improve the products and services we offer. See the section below on “How Do We Use Your Data?”`}</p>
    <p><strong parentName="p">{`3`}{`.`}{` How Do We Collect Your Personal Data?`}</strong></p>
    <p>{`We collect information about you in the following ways:`}</p>
    <p><strong parentName="p">{`Information You Give Us.`}</strong>{` This may include data you provide when you register to use our Services, any comment or other submission you post to our Sites, any information you provide in connection with a promotion or marketing/sales program we operate, any data you provide with regard to customer support, any data you provide when you correspond with us by phone, email, or otherwise, and any other information you may provide to us. When you agree to give us information, you provide us with the legal basis we require under applicable law to process your data. We will only process your personal data in accordance with applicable data protection and privacy laws. You maintain the right to withdraw such consent at any time.`}</p>
    <p><strong parentName="p">{`Information We Get From Others.`}</strong>{` We may also get information about you from other sources. For example, if your settings for your use of Social Media Sites permit it, we may access, use, and store the information that you agreed the Social Media Sites could provide in order to track your engagement with our content. We will access, use, and store that information in accordance with this Policy. You can revoke our access to the information you provide in this way at any time by amending the appropriate settings from within your account on the applicable Social Media Sites. If you have agreed to share information with one of our partners, we may add data about you collected by our partner to the information we already have.`}</p>
    <p><strong parentName="p">{`Information Automatically Collected.`}</strong>{` We automatically log information about you and your computer or mobile device when you access our Sites and Services. For example, we may log your computer or mobile device operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing to our Sites, pages you viewed, how long you spent on a page, and times and information about your use of and actions on our Sites and Services. We collect this information about you using cookies (see Cookies section below).`}</p>
    <p><strong parentName="p">{`Automated Decision Making and Profiling.`}</strong>{` We may use automated decision making and/or profiling in regard to your personal data for some services and products: for example, to evaluate your propensity and readiness to purchase our Services based on demographic data and activity history.`}</p>
    <p><strong parentName="p">{`Cookies.`}</strong>{` We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our Sites.`}</p>
    <p>{`Types of cookies`}</p>
    <p>{`You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings. If you do not accept our cookies, you may experience some inconvenience in your use of our Sites and Services. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit.`}</p>
    <p><strong parentName="p">{`Pixel Tags.`}</strong>{` We may also use pixel tags (which are also known as web beacons and clear GIFs) to track the actions of both anonymous and known users on our Sites. Pixel tags are embedded invisibly on webpages. Pixel tags measure engagement with our site and content to help use provide relevant information to visitors and is used to determine the success of our marketing campaigns so that we can manage our programs more effectively. The behavioral and campaign information we collect using pixel tags is linked to a users’ personal data contact record for only known visitors.`}</p>
    <p><strong parentName="p">{`4`}{`.`}{` How Do We Use Your Data?`}</strong></p>
    <p>{`We may use your personal data as follows, depending on whether you are a general user of our Sites or Services, or a Customer:`}</p>
    <ul>
      <li parentName="ul">{`To operate, maintain, and improve our Sites and Services.`}</li>
      <li parentName="ul">{`To fulfill the requirements and activities specified in the Customer’s Master Services Agreement and related Order Forms and Statements of Work .`}</li>
      <li parentName="ul">{`To administer Customer systems, test systems, and develop and implement changes.`}
        <ul parentName="li">
          <li parentName="ul">{`Any temporary copies of Customer data created as a necessary part of this process are only maintained for time periods relevant to those purposes.`}</li>
          <li parentName="ul">{`We may access and/or use a copy of the Customer’s test, development, or production environment, including Customer data, to test such new configurations, versions, patches, updates, and fixes, and to validate that they work to required specifications.`}</li>
          <li parentName="ul">{`DemandByte does not control its Customers’ information collection or the use practices for Customer data. If a Customer provides any Customer data to DemandByte, the Customer is solely responsible for providing any notices and/or obtaining any consents necessary for DemandByte to access and use Customer data as specified in this Policy and the Customer’s Master Services Agreement.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`To share with our third party service providers and subcontractors who provide services such as data analysis, payment processing, information technology and related infrastructure provision, customer service, email delivery, auditing, and other similar services. These third parties are only permitted to use your personal data to the extent necessary to enable them to provide their services to us. They are required to follow our express instructions and to comply with appropriate security measures to protect your personal data.`}</li>
      <li parentName="ul">{`To share with third parties where you have provided your consent to do so.`}</li>
      <li parentName="ul">{`To send customer service information including technical notices, updates, security alerts, and support and administrative messages.`}</li>
      <li parentName="ul">{`With your consent, to send you marketing e-mails about educational content, upcoming promotions, and other news, including information about products and services offered by us and our affiliates. You may opt out of receiving such information at any time; such marketing emails tell you how. Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you.`}</li>
      <li parentName="ul">{`To link or combine user information with other personal data.`}</li>
      <li parentName="ul">{`As we believe necessary or appropriate (a) to comply with applicable law, (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities to meet national security or law enforcement requirements, (c) to enforce our Policy, and (d) to protect our rights, privacy, safety, or property, and/or that of you or others.`}</li>
    </ul>
    <p>{`DemandByte does not share your personal information with non-affiliated third parties.`}</p>
    <p><strong parentName="p">{`5`}{`.`}{` How is Your Data Used Internationally?`}</strong></p>
    <p>{`Your information, including personal data that we collect from you, may be transferred to, stored at and processed by us and other third parties outside the country in which you reside, including, but not limited to the United States, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By using our Sites and Services, you agree to this transfer, storing, or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy.`}</p>
    <p><strong parentName="p">{`6`}{`.`}{` How Is Your Data Secured?`}</strong></p>
    <p>{`DemandByte employs reasonable organizational, technical, and administrative measures to protect personal data within our organization. Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the internet is not completely secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us of the problem.`}</p>
    <p><strong parentName="p">{`7`}{`.`}{` How Long Is Your Data Retained?`}</strong></p>
    <p>{`We retain personal information only as necessary for the duration of the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.`}</p>
    <p><strong parentName="p">{`8`}{`.`}{` What About Children?`}</strong></p>
    <p>{`Our Sites and Services are not directed to children under 16`}{`.`}{` If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.`}</p>
    <p><strong parentName="p">{`9`}{`.`}{` What About Sensitive Information?`}</strong></p>
    <p>{`We ask that you not send us or disclose any sensitive personal data (for example, social security numbers, information related to racial or ethnic origin, sexual orientation, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background, or trade union membership) on or through the Sites or via other means.`}</p>
    <p><strong parentName="p">{`10`}{`.`}{` What Are Your Rights With Regard to Your Personal Data?`}</strong></p>
    <p><strong parentName="p">{`Opt out.`}</strong>{` You can always opt not to disclose information. If you elect to do so, however, we will likely be limited in responding to your inquiry, or you may not be able to take advantage of some of our Sites and Services. You may contact us anytime to opt out of (a) direct marketing communications, (b) any new processing of your personal data that we may carry out beyond the original purpose, or (c) the transfer of your personal data outside the United States.`}</p>
    <p><strong parentName="p">{`Access.`}</strong>{` If you would like to access, correct, amend, remove, or limit the use or disclosure of any personal data about you that has been collected and stored by DemandByte, notify us so that we may consider and respond to your request in accordance with applicable law. For your protection, we only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we need to verify your identity before implementing your request. We will respond to your request within 30 days.`}</p>
    <p><strong parentName="p">{`Corrections and Updates.`}</strong>{` It’s best for both you and us if the information we have on record about you is complete and accurate, so we encourage you to correct any information that might be outdated or otherwise invalid. You also have the right to request erasure of your data. Please note that we need to retain certain information for record keeping purposes and/or to complete any transactions that you began prior to requesting such access, change or deletion.`}</p>
    <p><strong parentName="p">{`Data Protection Authorities.`}</strong>{` You may have the right to complain to a data protection authority about our collection and use of your personal data. For more information, please contact your local data protection authority in the European Economic Area (EEA), Switzerland, and certain non-European countries including the US and Canada.`}</p>
    <p>{`Contact us at `}<a parentName="p" {...{
        "href": "mailto:info@demandbyte.com"
      }}>{`info@demandbyte.com`}</a>{` to exercise any of these rights.`}</p>
    <p><strong parentName="p">{`Complaints`}</strong></p>
    <p>{`We are committed to resolving any complaints about our collection or use of your personal data. For more information, please contact your local data protection authority. Contact us at `}<a parentName="p" {...{
        "href": "mailto:info@demandbyte.com"
      }}>{`info@demandbyte.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      